var CarouselTileJS = new function () {
    var initialized = false;

    return {
        Init: function () {
            if (initialized) {
                return;
            }

            initialized = true;

            //line-clamp doesn't work on IE so just always show the full content
            if (window.navigator.userAgent.indexOf("MSIE ") != -1 || window.navigator.userAgent.match(/Trident.*rv\:11\./)) {
                return;
            }

            $(document).ready(showMoreButtons);

            $(window).resize(function () {
                $('.dashboard-sub-card').removeClass('expandable');
                showMoreButtons();
            });

            function showMoreButtons() {
                //Hide show more button if the text is larger than 5 lines
                $('.dashboard-sub-text').each(function () {
                    //Height of div
                    var divheight = $(this).height();
                    //Line height
                    var lineheight = parseInt($(".dashboard-sub-text").css('line-height'), 10);

                    //Show read more and clamp line if its larger than 5 lines
                    if ((Math.round(divheight / lineheight)) > 5) {
                        $(this).closest('.dashboard-sub-card').addClass('expandable');
                    } else {
                        $(this).closest('.dashboard-sub-card').removeClass('expandable');
                    }
                });
            }

            function getWexCardCapacity() {
                if (window.innerWidth <= 770) {
                    return 1;
                } else if (window.innerWidth <= 1080) {
                    return 2;
                } else if (window.innerWidth <= 1380) {
                    return 3;
                } else {
                    return 4;
                }
            }

            function updateWexCardsDisplay() {
                $('.wexcard').show();
                $('.wexcard-display-previous').hide();

                if ($('.wexcard').length > wexCardCapacity) {
                    $('.wexcard').slice(wexCardCapacity).hide();
                    $('.dashboard-wexcards-displaying').show();
                } else {
                    $('.dashboard-wexcards-displaying').hide();
                }

                $('.wexcards-first-displayed').text(firstWexCardDisplayed);
                $('.wexcards-last-displayed').text(firstWexCardDisplayed + wexCardCapacity - 1);

                if (wexCardCapacity == 1) {
                    $('.wexcards-hyphen-displayed').hide();
                    $('.wexcards-last-displayed').hide();
                } else {
                    $('.wexcards-hyphen-displayed').show();
                    $('.wexcards-last-displayed').show();
                }
            }

            //Show entire text and show "show less" button
            $(".dashboard-sub-read").click(function () {
                $(this).closest('.dashboard-sub-card').addClass('expanded');
            });

            //Truncate text and show "show more button"
            $(".dashboard-sub-less").click(function () {
                $(this).closest('.dashboard-sub-card').removeClass('expanded');
            });

            let firstWexCardDisplayed = 1;
            let wexCardCapacity = getWexCardCapacity();
            updateWexCardsDisplay();

            $(window).on('resize', function () {
                const newCapacity = getWexCardCapacity();
                if (newCapacity == wexCardCapacity)
                    return;

                wexCardCapacity = newCapacity;
                firstWexCardDisplayed = 1;
                $('.wexcard-display-previous').hide();
                $('.wexcard-display-next').show();
                updateWexCardsDisplay();
            });

            $('.wexcard-display-previous').click(function () {
                const lastDisplayedCard = $('.wexcard:visible').last();
                const previousHiddenCard = $(lastDisplayedCard).prevAll('.wexcard:hidden').first();

                lastDisplayedCard.hide();
                previousHiddenCard.show();

                firstWexCardDisplayed--;
                $('.wexcards-first-displayed').text(firstWexCardDisplayed);
                $('.wexcards-last-displayed').text(firstWexCardDisplayed + wexCardCapacity - 1);

                $('.wexcard-display-next').show();
                if ($(lastDisplayedCard).prevAll('.wexcard:hidden').length === 0) {
                    $('.wexcard-display-previous').hide();
                }
            });

            $('.wexcard-display-next').click(function () {
                const firstDisplayedCard = $('.wexcard:visible').first();
                const nextHiddenCard = $(firstDisplayedCard).nextAll('.wexcard:hidden').first();

                firstDisplayedCard.hide();
                nextHiddenCard.show();

                firstWexCardDisplayed++;
                $('.wexcards-first-displayed').text(firstWexCardDisplayed);
                $('.wexcards-last-displayed').text(firstWexCardDisplayed + wexCardCapacity - 1);

                $('.wexcard-display-previous').show();
                if ($(firstDisplayedCard).nextAll('.wexcard:hidden').length === 0) {
                    $('.wexcard-display-next').hide();
                }
            });

            $('.wexcard').each(function () {
                var planYearCount = $(this).find('.wexcard-wrapper').length;
                if (planYearCount > 1) {
                    $(this).find('.wexcard-wrapper').last().prevAll().addClass('wexcard-hidden');
                }
            })

            $(".wexcard-previous").click(function () {
                $(this).closest('.wexcard-wrapper').addClass('wexcard-hidden');
                $(this).closest('.wexcard-hidden').prev().removeClass('wexcard-hidden');
            });

            $(".wexcard-next").click(function () {
                $(this).closest('.wexcard-wrapper').addClass('wexcard-hidden');
                $(this).closest('.wexcard-wrapper').next().removeClass('wexcard-hidden');
            });
        }
    }
}();